import { Task, IValidationError, ITaskValidationError, ICheckRefTaskActionStatus, ITaskActionType, ITagChoose, IWorkflow } from '@/interface/workflow'
import { IDSL } from '@/interface/dsl'
import { Choose, findTaskByName, flattenObject } from '@/utils/utils'
import { cloneDeep, isEmpty, isEqual, omit } from 'lodash'
import moment from 'moment'
import { Modal } from '@hose/eui'
import WorkflowIndexDB from '@/utils/helpers/WorkflowIndexDB'
import { workflowStore, validateStore } from '@/store'

/**
 * 添加错误信息
 * @param validationErrors 
 * @param task 
 * @param errors 
 */
export const addValidationError = ({ validationErrors, task, errors }: { validationErrors: ITaskValidationError[], task: Task, errors: IValidationError[] }) => {
  const activeTask = validationErrors.find(item => item.task?.name === task.name);

  if (activeTask) {
    activeTask.errors = [...activeTask.errors, ...errors];
  } else {
    validationErrors.push({
      task,
      errors
    });
  }
}

/**
 * 添加表单错误信息
 * @param validationErrors 
 * @param task 
 * @param errors 
 */
export const addFormValidationError = ({ validationErrors, task, errors }: { validationErrors: ITaskValidationError[], task: Task, errors: IValidationError[] }) => {
  if (!errors?.length) {
    const index = validationErrors.findIndex(item => item.task?.name === task.name)
    if (index !== -1) {
      validationErrors.splice(index, 1)
    }
    return
  }

  const activeTask = validationErrors.find(item => item.task?.name === task.name)
  if (activeTask) {
    activeTask.errors = errors
  } else {
    validationErrors.push({
      task,
      errors
    })
  }
}

/**
 * 获取错误信息列表
 * @param errors 
 * @param dslJson 
 * @returns 
 */
export const getErrorList = (errors: IValidationError[], dslJson: any) => {
  return errors?.map?.((error: any) => {
    const fieldKey = error.address || error.path
    const fieldName = dslJson?.properties?.[error.path]?.title || error.path
    return {
        fieldKey,
        fieldName,
        message: `${fieldName || fieldKey},${error.messages?.[0]}`,
        groupBy: error.messages?.[0] || '',
    }
  })
}

/**
 * 获取store中错误信息列表
 * @param validationErrors 
 * @param formValidationErrors 
 * @returns 
 */
export const getStoreErrorList = (validationErrors: ITaskValidationError[], formValidationErrors: ITaskValidationError[]): ITaskValidationError[] => {
  const mergedErrorsMap = {};
  // 合并两个错误数组
  const allErrors = [...validationErrors, ...formValidationErrors];

  allErrors.forEach(errorItem => {
    const taskName = errorItem.task?.name;
    
    if (!mergedErrorsMap[taskName]) {
      mergedErrorsMap[taskName] = {
        task: { ...errorItem.task },
        errors: []
      };
    }
    
    mergedErrorsMap[taskName].errors = [...mergedErrorsMap[taskName].errors, ...errorItem.errors]
  });
  // 转换为最终数组格式
  return Object.values(mergedErrorsMap).sort((prev: any, next: any) => (prev.task?.orderIndex || 0) - (next.task?.orderIndex || 0)) as ITaskValidationError[];
}

/**
 * 检查节点引用关系
 * @param refTask 
 * @param currentTask 
 * @param choose 
 * @returns 
 */
export const checkControlReference = (refTask: Task, currentTask: Task, choose: Choose) => {
  // 1. 检查是否引用了循环节点的结果列表
  if (refTask.groupName === 'control' && choose?.properties?.some?.(p => p.key === '$this')) {
    // 检查当前节点是否在循环节点内部
    const isInsideLoop = currentTask.levelIndex.length > refTask.levelIndex.length && 
      currentTask.levelIndex.slice(0, refTask.levelIndex.length).every((value, index) => 
        value === refTask.levelIndex[index]
      );

    // 如果当前节点在循环节点内部，不允许引用循环节点的结果列表
    if (isInsideLoop) {
      return false;
    }
  }
  
  // 2.如果引用了循环节点的 $item
  if (refTask.groupName === 'control' && choose?.properties?.some?.(p => p.key === '$item')) {
    // 处理 levelIndex
    const refLevelIndex = [0, ...refTask.levelIndex]
    const taskLevelIndex = [0, ...currentTask.levelIndex]
    
    // 检查当前节点是否在循环节点的作用域内
    const isSameScope = refLevelIndex.every((value, index) => {
      // 只检查到循环节点的层级
      if (index <= refLevelIndex.length - 1) {
        return value === taskLevelIndex[index]
      }
      return true
    })
    
    return isSameScope
  }
  return true
}

/**
 * 至少添加 1 个动作节点
 * @param tasks 
 * @returns 
 */
export const checkMinActionNode = (tasks: Task[]) => {
  return tasks.length === 1
}

/**
 * 流程节点至少添加 1 个节点
 * @param tasks 
 * @returns 
 */
export const checkMinProcessNode = (currentTask: Task, groupDsl: { [key: string]: IDSL[] }) => {
  const controlNodes = groupDsl?.control || [];
  const controlTypes = controlNodes.map(item => item.type);
  return controlTypes.includes(currentTask.type) && isEmpty(currentTask.tasks)
}

/**
 * 根据 actionStatus 添加错误信息
 * @param task 
 * @param actionStatus 
 * @param validationErrors 
 * @param errors 
 */
export const addValidationErrorForActionStatus = (task: Task, actionStatus: ICheckRefTaskActionStatus, validationErrors: ITaskValidationError[], errors: IValidationError[]) => {
  if (isEmpty(task) || !task?.name || !task?.type) return
  if (['all', 'update_task_status'].includes(actionStatus)) {
    task.status = 'error'
  }
  if (['all', 'push_validation_errors'].includes(actionStatus)) {
    addValidationError({
      validationErrors: validationErrors || [],
      task: { ...task },
      errors
    });
  }
}

/**
 * 如果表单值不存在，默认赋值task
 * 如果task.formilyFormValues有值，只保留formilyFormValues中存在的字段
 */
export const taskFormilyFormValues = (task: Task & {
  formilyFormValues?: Record<string, any>;
}) => {
  if (isEmpty(task.formilyFormValues)) {
    task.formilyFormValues = cloneDeep(task);
  }
}

/**
 * 过滤出表单值
 * 如果task.formilyFormValues有值，只保留formilyFormValues中的表单值
 * @param task 
 * @returns 
 */
export const filterTaskForFormValues = (task: Task & {
  formilyFormValues?: Record<string, any>;
}) => {
  let _task = cloneDeep(task)
  if (!isEmpty(task.formilyFormValues)) {
    _task = cloneDeep(task.formilyFormValues);
  }
  return _task
}

/**
 * 删除task中没有用的字段
 * @param task 
 */
export const deleteNoUseFieldTask = (task: Task & {
  formilyFormValues?: Record<string, any>;
}) => {
  if (!isEmpty(task.formilyFormValues)) {
    delete task.formilyFormValues
  }
}

/**
 * 检查节点引用关系
 * @param _tasks 
 * @param task 
 * @param currentTask 
 * @param validationErrors 
 * @param actionStatus 'update_task_status' 仅更新task状态 'push_validation_errors' 仅保存异常报错  'all' 保存异常报错并更新task状态
 */
export const checkNodeReference = (_tasks: Task[], task: Task, currentTask: Task, actionStatus: ICheckRefTaskActionStatus, validationErrors?: ITaskValidationError[]) => {
  // 将 task 所有 key 拍平
  const flattenTask = flattenObject(filterTaskForFormValues(task))
  // 过滤出以 .value 结尾的 key，并检查这些 value 是否是表达式
  const valueKeyPaths = Object.keys(flattenTask).filter(key => {
    const value = flattenTask[key]
    return typeof value === 'string' && value?.includes('@{choose(')
  })
  // 现在 valueKeyPaths 中存储了所有可能存在错误引用的表达式
  // 结构化表达式，并检查是否存在错误引用
  const structuredChooses: Choose[] = []
  valueKeyPaths.forEach(key => {
    const value = flattenTask[key]
    const REX = /@{choose\('(.+?)'\)}/g
    // 使用 match() 方法获取所有匹配
    const matches = value.match(REX)
    // 输出捕获的内容
    if (matches) {
      matches.forEach(match => {
        try {
          // 处理匹配的 choose 字符串 @{choose('...')}
          // 去掉 @{choose(' 和 ')}
          let str = match.replace(/@{choose\(|\)}/g, '').replace(/'/g, '')
          // 目前测试出表达式格式存在 bug, 会有 }} 结尾处多一个 } 应该去掉
          str = str.replace(/}}$/, '}') // 将结尾的 }} 替换为单个 }

          // 添加这一行来解决转义问题
          str = str.replace(/\\"/g, '"')

          // 将 str 转换为 JSON 对象
          const choose = JSON.parse(str) as Choose

          structuredChooses.push(choose)
        } catch (error) {
          console.error(error)
        }
      })
    } else {
      console.log('No matches found')
    }
  })
  // 检查 structuredChoose 是否存在错误引用

  // 3️⃣ 检查引用关系
  return structuredChooses.some(choose => {
    // choose 中 key 为引用节点的 name

    // 3.0 全局变量暂时不校验引用不存在
    if (choose?.key === '$variables') {
      return false;
    }

    // 3.1 判断 choose 中 key 是否在 _tasks 中存在
    const refTask = findTaskByName(_tasks, choose.key)
    const orderIndexPrefix = refTask?.orderIndex ? `第${refTask?.orderIndex}步：` : '';
    const refTaskPath = `${orderIndexPrefix} ${choose.label || ''}`;

    if (!refTask) {
      addValidationErrorForActionStatus(task, actionStatus, validationErrors || [], [{
        refTaskName: choose.key,
        message: `引用变量不存在,${refTaskPath}`,
        groupBy: '引用变量不存在',
      }]);
      return true
    }

    // 3.2 判断 refTask 的 orderIndex 是否小于 task 的 orderIndex（节点只能引用上文节点）
    if (refTask?.orderIndex > task?.orderIndex) {
      addValidationErrorForActionStatus(task, actionStatus, validationErrors || [], [{
        refTaskName: refTask.name,
        message: `引用变量不存在，${refTaskPath} `,
        groupBy: '引用变量不存在',
      }]);
      return true
    }

    // 3.3 检查流程节点内被其他引用
    if (!checkControlReference(refTask, task, choose)) {
      addValidationErrorForActionStatus(task, actionStatus, validationErrors || [], [{
        refTaskName: refTask.name,
        message: `引用变量不存在，${refTaskPath} `,
        groupBy: '引用变量不存在',
      }]);
      return true
    }

    // 3.4 判断 refTask 和 task 的 levelIndex，是否同父或者同祖

    // 重构判断方法
    // 首先在两个 levelIndex 第一位补一个 0 ，代表根节点
    // 然后对比在 两个 levelIndex 去掉最后一位 代表父节点，相当于作用域
    // 最后逐个对比处理好的 levelIndex 是否相等
    // 只要对齐 refLevelIndex 即可

    // 处理 levelIndex
    const refLevelIndex = refTask.levelIndex
    const taskLevelIndex = task.levelIndex

    // 3.4.1. 在两个 levelIndex 第一位补 0,代表根节点
    const processedRefIndex = [0, ...refLevelIndex]
    const processedTaskIndex = [0, ...taskLevelIndex]

    // 3.4.2. 去掉最后一位,代表父节点作用域
    const refScope = processedRefIndex.slice(0, -1)
    const taskScope = processedTaskIndex.slice(0, -1)

    // 3.4.3. 逐个对比处理好的 levelIndex 是否相等
    const isSame = refScope.every((_, i) => refScope[i] === taskScope[i])
    if (!isSame) {
      addValidationErrorForActionStatus(task, actionStatus, validationErrors || [], [{
        refTaskName: refTask.name,
        message: `引用变量不存在，${refTaskPath} `,
        groupBy: '引用变量不存在',
      }]);
      return true
    }
  })
}

/**
 * 实时异常检查核心方法：校验工作流完整型和引用关系
 * @param tasks 
 * @param groupDsl 
 * @param actionType 
 * @returns 
 */
export const checkWorkflowError = async (tasks: Task[], groupDsl: { [key: string]: IDSL[] }, actionType: ITaskActionType) => {
  const validationErrors: ITaskValidationError[] = [];
  try {
    const _tasks = cloneDeep(tasks)

    // 1️⃣ 至少添加 1 个动作节点
    if (checkMinActionNode(_tasks)) {
      addValidationError({
        validationErrors,
        task: tasks?.[0] || {},
        errors: [{
          message: '需至少添加 1 个动作节点',
          groupBy: '需至少添加 1 个动作节点',
        }]
      });
    }

    // 定义 task 检查方法
    const checkTask = async (currentTask: Task) => {
      // 2️⃣ 流程节点至少添加 1 个节点
      if (checkMinProcessNode(currentTask, groupDsl)) {
        addValidationError({
          validationErrors,
          task: currentTask,
          errors: [{
            message: '流程节点内至少添加1个节点',
            groupBy: '流程节点内至少添加1个节点',
          }]
        });
      }
      
      // 只检查当前节点，不遍历子节点
      const task = cloneDeep(currentTask)
      delete task.tasks
  
      checkNodeReference(_tasks, task, currentTask, 'push_validation_errors', validationErrors)
    }
    
    // 2️⃣ 递归检查 _tasks 中所有节点是否存在错误引用
    const checkTasks = async (tasks: Task[]) => {
      // 使用 Promise.all 等待所有异步操作完成
      await Promise.all(tasks.map(async (task) => {
        await checkTask(task)
        if (task.tasks) {
          await checkTasks(task.tasks)
        }
      }))
    }
  
    await checkTasks(_tasks)

    // const bus = workflowStore.getState().bus;

    // if (!bus) {
    //   console.error('Bus is not initialized, validate:tasks:form event not emit');
    //   return validationErrors;
    // }

    // if (['form_values_change', 'restore_task', 'add_task', 'copy_task', 'remove_task', 'save_and_debug_emit', 'drag_task_end'].includes(actionType)) {
    //   // 5️⃣ 添加表单验证逻辑（采用表单实例化方案 formily 的 validate 方法）
    //   bus.emit('validate:tasks:form', {
    //     validationErrors,
    //     tasks: cloneDeep(_tasks),
    //     groupDsl,
    //     actionType
    //   })
    //   return validationErrors;
    // }
    validateStore.getState().setValidationErrors(validationErrors)
		validateStore.getState().setWorkflowUpdateTime(moment().format('YYYY-MM-DD HH:mm:ss'))

    return validationErrors;
  } catch (error) {
    console.error('checkWorkflowError error', error)
    return validationErrors;
  }
}

let modalInstance: any = null;  // 保存 恢复Modal 实例

/**
 * 恢复工作流
 * @param id 
 * @param res 
 * @returns 
 */
export const workflowRestore = async (id: string, res: any) => {
  try {
      if (modalInstance) {
        modalInstance.destroy();
      }
  
      const [indexDBData, formValidationData] = await Promise.all([
        WorkflowIndexDB.getInstance().loadDraft(id),
        WorkflowIndexDB.getValidationInstance().loadFormValidation(id)
      ])

      if (!indexDBData) {
        workflowStore.getState().setWorkflow(res, false, false, { actionType: 'workflow_from_api' })
        return { ...res }
      }
  
      // 将 res.updateTime 转换为时间戳
      const updateTimeStamp = new Date(res.updateTime).getTime()


      if (indexDBData.createTime <= updateTimeStamp) {
        await WorkflowIndexDB.getInstance().clean(id);
        workflowStore.getState().setWorkflow(res, false, false, { actionType: 'workflow_from_api' });
        return res;
      }

      // 如果值相等也无需提示恢复
      if (isTasksEqualForIgnore(res.tasks, indexDBData?.data?.tasks)) {
        await WorkflowIndexDB.getInstance().clean(id);
        workflowStore.getState().setWorkflow(res, false, false, { actionType: 'workflow_from_api' });
        return res;
      }

      // indexDBData.createTime > updateTimeStamp提示是否恢复
      return await new Promise(async(resolve) => {
        modalInstance = Modal.confirm({
          title: '恢复未保存的配置？',
          content: '上次退出时，存在尚未保存的配置',
          okText: '恢复',
          cancelText: '放弃',
          onOk: () => {
            workflowStore.getState().setWorkflow({ ...indexDBData.data }, false, false, { actionType: 'restore_task' })// restore_task 恢复
            validateStore.getState().setFormValidationErrors([...formValidationData?.data || []])
            modalInstance = null;
            resolve({ ...indexDBData.data })
          },
          onCancel: async () => {
            await WorkflowIndexDB.getInstance().clean(id)
            workflowStore.getState().setWorkflow({ ...res }, false, false, { actionType: 'give_up_task' }) // give_up_task 放弃 
            modalInstance = null;
            resolve({ ...res })
          },
          afterClose: () => {
            modalInstance = null;
            resolve({ ...res })
          }
        });
      })
  } catch (error) {
    console.error('Failed to handle workflow restore:', error)
    workflowStore.getState().setWorkflow(res, false, false, { actionType: 'workflow_from_api' })
    modalInstance = null;
    return res;
  }
}

/**
 * 正则匹配映射错误信息
 * @param message 
 * @returns 
 */
const processLengthError = (message) => {
  // 使用正则匹配 "***最多**字符" 的模式
  const lengthPattern = /^([^最]+)最多\d+个字符$/;
  const match = message.match(lengthPattern);

  if (match) {
    const fieldName = match[1]; // 获取字段名
    return `${fieldName}过长`;
  }
  return message;
}

/**
 * 映射错误信息定义
 * @param message 
 * @returns 
 */
const groupByMapping: Record<string, string> = {
  '该字段是必填字段': '必填项不能为空',
};

/**
 * 映射错误信息
 * @param message 
 * @returns 
 */
export const mappingMessages = (message?: string) => {
  if (!message) return '';
  return groupByMapping[message] || processLengthError(message || '');
};

/**
 * 保存原有的 feedbacks
 * @param form 
 * @param throwError 
 * @param originalFeedbacks 
 * @returns 
 */
export const feedbacksForThrowErrorBefore = (form: any, throwError = false, originalFeedbacks = {}) => {
  if (!throwError) {
    Object.keys(form.fields).forEach(key => {
      const field = form.fields[key];
      if (field) {
        originalFeedbacks[key] = field.feedbacks;
        // 临时清空 feedbacks，这样就不会触发 UI 反馈
        field.feedbacks = [];
      }
    });
  }
}

/**
 * 恢复原有的 feedbacks
 * @param form 
 * @param throwError 
 * @param originalFeedbacks 
 * @returns 
 */
export const feedbacksForThrowErrorAfter = (form: any, throwError = false, originalFeedbacks = {}) => {
  if (!throwError) {
    Object.keys(originalFeedbacks).forEach(key => {
      const field = form.fields[key];
      if (field) {
        field.feedbacks = originalFeedbacks[key];
      }
    });
  }
}

/**
 * 样本数据错误信息
 */
export const SAMPLE_DATA_ERROR_MESSAGE = '请调试并生成样本数据'
export const WEBHOOK_SAMPLE_DATA_ERROR_MESSAGE = '请先获取样本数据'

/**
 * 处理特殊校验错误信息
 * @param values 
 * @param errors 
 * @returns 
 */
export const getErrorsForSpecialCheck = (values, errors: any[]) => {
  const newError = [...errors]

  // // http 节点的特殊校验
  // if (values.type === 'http' && !values?.sampleData) {
  //   newError.push({
  //     messages: [SAMPLE_DATA_ERROR_MESSAGE],
  //     address: 'sampleData',
  //     path: 'sampleData',
  //     type: 'error'
  //   })
  // }

  // webhook 节点的特殊校验
  if (values.type === 'webhook' && !values?.sampleId) {
    newError.push({
      messages: [WEBHOOK_SAMPLE_DATA_ERROR_MESSAGE],
      address: 'sampleId',
      path: 'sampleId',
      type: 'error'
    })
  }

  return newError;
}

/**
 * 校验引用标签
 * @param curTask 当前节点
 * @param refTask 引用节点
 * @param choose 引用标签
 * @returns true 有效 false 无效
 */
export const checkRefForContextual = (curTask: Task, refTask: Task, choose: ITagChoose) => {
  // 0️⃣ 全局变量暂时不校验引用不存在
  if (choose?.key === '$variables') {
    return true;
  }

  // 1️⃣ 判断 refTask 和 task 是否存在
  if (!refTask || !curTask) {
    return false
  }

  // 2️⃣ 判断 refTask 和 task 是否存在 orderIndex
  if (!refTask?.orderIndex || !curTask?.orderIndex) {
    return false
  }

  // 3️⃣ 判断 refTask 的 orderIndex 是否小于 task 的 orderIndex（节点只能引用上文节点）
  if (refTask?.orderIndex > curTask?.orderIndex) {
    return false
  }

  // 4️⃣ 检查节点引用关系（跨层级）
  if (!checkControlReference(refTask, curTask, choose as unknown as Choose)) {
    return false
  }

  // 5️⃣ 判断 refTask 和 task 的 levelIndex，是否同父或者同祖

  // 重构判断方法
  // 首先在两个 levelIndex 第一位补一个 0 ，代表根节点
  // 然后对比在 两个 levelIndex 去掉最后一位 代表父节点，相当于作用域
  // 最后逐个对比处理好的 levelIndex 是否相等
  // 只要对齐 refLevelIndex 即可

  // 处理 levelIndex
  const refLevelIndex = refTask.levelIndex
  const curLevelIndex = curTask.levelIndex

  // 1. 在两个 levelIndex 第一位补 0,代表根节点
  const processedRefIndex = [0, ...refLevelIndex]
  const processedCurIndex = [0, ...curLevelIndex]

  // 2. 去掉最后一位,代表父节点作用域
  const refScope = processedRefIndex.slice(0, -1)
  const curScope = processedCurIndex.slice(0, -1)

  // 3. 逐个对比处理好的 levelIndex 是否相等
  const isSame = refScope.every((_, i) => refScope[i] === curScope[i])
  if (!isSame) {
    return false
  }

  return true
}

/**
 * 通过 levelIndex 获取节点
 * @param workflow 工作流
 * @param levelIndex 层级索引
 * @returns 
 */
export const getTaskByLevelIndex = (workflow: IWorkflow, levelIndex: number[]) => {
  if (!workflow || !levelIndex?.length) return null;
  
  return levelIndex.reduce(
    (task, index) => task?.tasks?.[index] ?? null,
    workflow
  ) as unknown as Task
}

/**
 * 比较两个任务数组是否相等，忽略前端交互字段
 * @param tasks1 第一个任务数组
 * @param tasks2 第二个任务数组
 * @returns 是否相等
 */
export const isTasksEqualForIgnore = (tasks: any[], targetTasks: any[]) => {
  if (tasks?.length !== targetTasks?.length) return false;
  
  const cleanTasks = cloneDeep(tasks);
  const cleanTargetTasks = cloneDeep(targetTasks);
  
  const removeIgnoreField = (tasks: any[]) => {
    tasks.forEach(task => {
      delete task.runStatus; // 递归移除runStatus字段
      if (task.tasks && Array.isArray(task.tasks)) {
        removeIgnoreField(task.tasks);
      }
    });
  };
  
  removeIgnoreField(cleanTasks);
  removeIgnoreField(cleanTargetTasks);

  return isEqual(cleanTasks, cleanTargetTasks);
};

/**
 * 将 formValue 解构到 task
 * @param task 
 * @param values 
 * @returns 
 */
export const formValueToTask = (task: Task, values: any) => {
  if (!task) return;

  Object.assign(task, {
    formilyFormValues: omit(values, ["formilyFormValues", "tasks"]),
  });
};
