import { IResponseWorkflowList, IWorkflow } from '@/interface/workflow'
import { makeRestApiRequest as API } from '@/utils/helpers/RequestHelper'
// store
import { configStore, validateStore } from '@/store'
// utils
import { pickKeys } from '@/utils/pick-keys'
import { copyPipeFitter, parseSnapshot, restoreSnapshot, saveSnapshot } from '@/utils/middleware'
import { cloneDeep } from 'lodash'
// ----------------------------------------------------------------------

export function getAllWorkflows(page: number, size: number): Promise<IResponseWorkflowList> {
  const source = configStore.getState().source
  return API('GET', '/api/easo/pipelines/logs', {
    number: page,
    pageSize: size,
    platformId: configStore.getState().$platformId,
    source: source === 'APP_INSTALL' ? 4 : undefined
  })
}

/**
 * 根据id获取单个工作流
 * @param id 工作流id
 * @returns
 */
export function getWorkflowById(id: string): Promise<IWorkflow> {
  return API('GET', `/api/easo/pipelines/${id}`).then(res => {
    res?.id && validateStore.getState().setContextDeep(res.id)
    return res
  })
}

/**
 * 新增工作流
 * @param workflow 工作流
 * @returns
 */
export function postNewWorkflow(workflow: IWorkflow, showSuccessMessage: boolean = true): Promise<IWorkflow> {
  const inputs = {
    key: '$platformId',
    value: configStore.getState().$platformId
  }
  workflow.inputs = [...(workflow?.inputs ?? []), inputs]

  // 过滤前端不需要的字段
  pickKeys(workflow)

  // 提交的时候转换快照格式
  workflow.tasks = workflow.tasks.map(task => parseSnapshot(task))

  saveSnapshot(workflow)

  workflow.platformId = configStore.getState().$platformId
  return API('POST', '/api/easo/pipelines', workflow, false, showSuccessMessage).then(res => {
    res?.id && validateStore.getState().setContextDeep(res.id)
    restoreSnapshot(res)
    return res
  })
}

/**
 * 修改工作流
 * @param id 工作流id
 * @param workflow 工作流数据
 * @returns
 */
export function updateWorkflow(id: string, workflow: IWorkflow): Promise<IWorkflow> {
  const copyWorkflow = cloneDeep(workflow)
  const inputs = {
    key: '$platformId',
    value: configStore.getState().$platformId
  }
  copyWorkflow.inputs = [...(copyWorkflow?.inputs ?? []), inputs]

  // 过滤前端不需要的字段
  pickKeys(copyWorkflow)
  // 提交的时候转换快照格式
  copyWorkflow.tasks = copyWorkflow.tasks.map(task => parseSnapshot(task))

  saveSnapshot(copyWorkflow)

  return API('PUT', `/api/easo/pipelines/${id}`, copyWorkflow).then(res => {
    res?.id && validateStore.getState().setContextDeep(res.id)
    restoreSnapshot(res)
    return res
  })
}

/**
 * 修改工作流状态
 * @param id 工作流id
 * @param state 状态，0：表示禁用，1：表示启用
 * @returns
 */
export function changeWorkflowState(id: string, state: number): Promise<IWorkflow> {
  return API('PUT', `/api/easo/pipelines/state/${id}?state=${state}`)
}

/**
 * 删除工作流
 * @param id 工作流id
 * @returns
 */
export function delWorkflow(id: string): Promise<IWorkflow> {
  return API('DELETE', `/api/easo/pipelines/${id}`)
}

/**
 * 修改工作流名称
 * @param label
 * @param id 工作流id
 * @returns
 */
export function updateWorkflowName(label: string, id: string | number): Promise<IWorkflow> {
  return API('PATCH', `/api/easo/pipelines/${id}`, { label })
}

/**
 * 修改工作流描述
 * @param desc 新描述
 * @param id 工作流id
 * @returns
 */
export function updateWorkflowDesc(desc: string, id: string | number): Promise<IWorkflow> {
  return API('PUT', `/api/easo/pipelines/desc/${id}?desc=${desc}`)
}

/**
 *  复制工作流
 * @param id 工作流id
 * @param workflow 工作流数据
 * @returns
 */
export function copyWorkflow(id: string, workflow: IWorkflow): Promise<IWorkflow> {
  return API('PUT', `/api/easo/pipelines/${id}/copy`, copyPipeFitter(workflow))
}

/**
 * 修改工作流调试状态
 * @param id 工作流id
 * @param state
 */
export function UpdateFlowDebugState(id: string, state: number): Promise<any> {
  return API('PUT', `/api/easo/pipelines/debugState/${id}?debugState=${state}`)
}

/**
 * 获取工作流的上下文
 * @param id 工作流id
 * @returns
 */
export function getWorkflowContextDeep(id: string): Promise<any> {
  return API('PUT', `/api/easo/pipelines/${id}/chooses`)
}

/**
 * 实时获取工作流的上下文
 * @param id 工作流id
 * @returns
 */
export function getWorkflowStageContextDeep(id: string, workflow: IWorkflow): Promise<any> {
  return API('POST', `/api/easo/pipelines/stage/${id}/chooses`, workflow, true)
}

/**
 * 获取工作流运行历史
 * @param id 工作流id
 *
 * @returns
 */

export type PageQuery = {
  number: number
  pageSize: number
}

export function workflowSnapshots(id: string, queryEn: PageQuery = { number: 1, pageSize: 10 }): Promise<any> {
  return API('GET', `/api/easo/pipelines/${id}/snapshots`, queryEn)
}

export function getSnapshot(id: string): Promise<any> {
  return API('GET', `/api/easo/pipelines/snapshot/${id}`)
}

/**
 * 修改工作流名称
 * @param id 工作流id
 * @param label: string, desc:string
 * @returns
 */
export function updateWorkflowParams(id: string | number, params): Promise<IWorkflow> {
  return API('PATCH', `/api/easo/pipelines/${id}`, params)
}

/**
 * 新增失败提醒配置
 * @param id 工作流id
 * @param label: string, desc:string
 * @returns
 */
export function setFailReminderConfig(pipelineId: string | number, params): Promise<IWorkflow> {
  return API('PUT', `/api/easo/pipelines/${pipelineId}`, params)
}

/**
 * 工作流创建前获取应用变量
 */
export function getWorkflowVariables(): Promise<any> {
  const platformId = configStore.getState().$platformId
  return API('PUT', `/api/easo/platform/${platformId}/chooses`)
}
