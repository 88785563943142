import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

type PanelName = 'history' | 'nodeSetting' | 'setTrigger' | ''
type ValidatePanelName = 'validate' | ''

interface IStore {
  actionPanelName: PanelName
  validatePanelName: ValidatePanelName
  setPanel: (name: PanelName) => void
  setValidatePanel: (name: ValidatePanelName) => void
}

const layout = create<IStore>()(
  devtools(set => ({
    actionPanelName: '',
    validatePanelName: '',
    setPanel: (name: PanelName) => set({ actionPanelName: name }),
    setValidatePanel: (name: ValidatePanelName) => set({ validatePanelName: name })
  }))
)

export default layout
