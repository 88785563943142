import { openDB, DBSchema } from 'idb';
import { IWorkflow, ITaskActionType, ITaskValidationError } from '@/interface/workflow';
import { cloneDeep } from 'lodash';

interface WorkflowDB extends DBSchema {
  'workflow_drafts': {
    key: string;  // workflowId
    value: {
      workflowId: string;
      data: IWorkflow;
      createTime: number;
      version: number;
      status: 'autosaved';
    };
    indexes: {
      'by_workflow': string;
      'by_user': string;
      'by_time': number;
    };
  };
  'form_validation': {
    key: string;  // workflowId_v
    value: {
      workflowId: string;
      data: ITaskValidationError[];
      createTime: number;
    };
    indexes: {
      'by_workflow': string;
      'by_time': number;
    };
  };
}

export default class WorkflowIndexDB {
  private static instance: WorkflowIndexDB;
  private static validationInstance: WorkflowIndexDB;
  private readonly dbName = 'workflow_db';
  private readonly validationDBName = 'validation_db';
  private readonly version = 1;
  private db: any;
  private validationDB: any;


  private constructor() {}

  public static getInstance(): WorkflowIndexDB {
    if (!WorkflowIndexDB.instance) {
      WorkflowIndexDB.instance = new WorkflowIndexDB();
    }
    return WorkflowIndexDB.instance;
  }

  public static getValidationInstance(): WorkflowIndexDB {
    if (!WorkflowIndexDB.validationInstance) {
      WorkflowIndexDB.validationInstance = new WorkflowIndexDB();
    }
    return WorkflowIndexDB.validationInstance;
  }

  private async initDB() {
    if (!this.db) {
      this.db = await openDB<WorkflowDB>(this.dbName, this.version, {
        upgrade(db) {
          const store = db.createObjectStore('workflow_drafts', { keyPath: 'key' });
          store.createIndex('by_workflow', 'workflowId');
          store.createIndex('by_time', 'createTime');

          const validationStore = db.createObjectStore('form_validation', { keyPath: 'key' });
          validationStore.createIndex('by_workflow', 'workflowId');
          validationStore.createIndex('by_time', 'createTime');
        },
      });
    }
    return this.db;
  }

  private async initValidationDB() {
    if (!this.validationDB) {
      this.validationDB = await openDB(this.validationDBName, this.version, {
        upgrade(db) {
          const store = db.createObjectStore('form_validation', { keyPath: 'key' });
          store.createIndex('by_workflow', 'workflowId');
          store.createIndex('by_time', 'createTime');
        },
      });
    }
    return this.validationDB;
  }

  private getCreateTime(workflow: IWorkflow, actionType: ITaskActionType): number {
    return actionType === 'give_up_task' && workflow.updateTime ? new Date(workflow.updateTime).getTime() : Date.now()
  }

  public async saveDraft(workflow: IWorkflow, actionType: ITaskActionType): Promise<void> {
    const key = workflow.id;
    if (!key) return;
    const createTime = this.getCreateTime(workflow, actionType)
    const draft = {
      key,
      workflowId: workflow.id,
      data: cloneDeep(workflow),
      createTime,
      version: 1,
      status: 'autosaved'
    };

    const db = await this.initDB();

    await db.put('workflow_drafts', draft);
  }

  public async loadDraft(workflowId?: string): Promise<any> {
    const db = await this.initDB();
    const key = workflowId;
    return await db.get('workflow_drafts', key);
  }

  public async cleanDraft(workflowId: string): Promise<void> {
    const db = await this.initDB();
    const key = workflowId;
    await db.delete('workflow_drafts', key);
  }

  public async saveFormValidation(workflowId: string, data: ITaskValidationError[]): Promise<void> {
    if (!workflowId) return;
    const key = `${workflowId}_v`;
    const validation = {
      key,
      workflowId,
      data: cloneDeep(data),
      createTime: Date.now()
    };

    const db = await this.initValidationDB();

    await db.put('form_validation', validation);
  }

  public async loadFormValidation(workflowId: string): Promise<any> {
    const db = await this.initValidationDB();
    const key = `${workflowId}_v`;
    return await db.get('form_validation', key);
  }

  public async cleanFormValidation(workflowId: string): Promise<void> {
    const db = await this.initValidationDB();
    const key = `${workflowId}_v`;
    await db.delete('form_validation', key);
  }

  public clean(workflowId: string): Promise<any> {
    if (!workflowId) return Promise.resolve([])
    return Promise.all([
      this.cleanDraft(workflowId),
      this.cleanFormValidation(workflowId)
    ])
  }
}