import { Task, IWorkflow } from '@/interface/workflow'
import moment from 'moment'
import { customAlphabet } from 'nanoid'
import { cloneDeep, get, unset, isEmpty } from 'lodash'
import { checkNodeReference, filterTaskForFormValues, deleteNoUseFieldTask } from '@/utils/helpers/workflowValidate'
import { parseSnapshot } from '@/utils/middleware'
import { compareSessionWorkflow } from '@/utils/sessionWorkflow'
import { trialStore } from '@/store'

/**
 * 根据dsl名称生成节点名称
 * @param groupName 节点分组
 * @param tasks 当前工作流所有节点
 * @param name dsl的名称
 * @returns 名称
 */
export function genderNodeName(groupName: string) {
  const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyz0123456789', 6)
  const id = nanoid()
  return `${groupName?.replaceAll('/', '')}_${id}`
}

/**
 * @description 函数重新排序
 * @author Yun.kou
 */
export function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

// ----------------------------------------------------------------------
// sonar 安全问题：Origins should be verified during cross-origin communications
// 目前需要 postMessage 通信的地址有：
// 测试环境：https://unity-dev.ekuaibao.net/
// release环境：https://unity-test.ekuaibao.net/
// 正式环境：https://unity.ekuaibao.com/
// QA环境：https://unity-pre.ekuaibao.com/

export const postMessage = (data: any) => {
  window?.parent?.postMessage(data, 'https://unity-dev.ekuaibao.net/')
  window?.parent?.postMessage(data, 'https://unity-test.ekuaibao.net/')
  window?.parent?.postMessage(data, 'https://unity.ekuaibao.com/')
  window?.parent?.postMessage(data, 'https://unity-pre.ekuaibao.com/')
}

// ----------------------------------------------------------------------
//  将object 多层级格式改转换成用keyPath为一层级的格式
export const flattenObject = (obj: Object, prefix = '') =>
  Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + '.' : ''
    if (typeof obj[k] === 'object' && obj[k] !== null) {
      Object.assign(acc, flattenObject(obj[k], pre + k))
    } else {
      acc[pre + k] = obj[k]
    }
    return acc
  }, {})

export function findTaskByName(tasks: Task[], targetName: string) {
  for (const task of tasks) {
    if (task?.name === targetName) {
      return task
    }
    if (task?.tasks) {
      const foundTask = findTaskByName(task.tasks, targetName)
      if (foundTask) {
        return foundTask
      }
    }
  }
  return undefined
}

export function mergeTaskValues(task: Task, values: any) {
  const newTask = { ...task }  // 保持原有字段顺序
  
  // 先处理 task 中已有的字段（保持顺序）
  Object.keys(task).forEach(key => {
    newTask[key] = values[key] !== undefined ? values[key] : task[key]
  })
  
  // 再添加 values 中有但 task 中没有的新字段
  Object.keys(values).forEach(key => {
    if (!(key in task) && values[key] !== undefined && values[key] !== null) {
      newTask[key] = values[key]
    }
  })
  
  return newTask
}

export function filterTasks(tasks: any[]): any[] {
  if (!tasks || !Array.isArray(tasks)) return [];

  return tasks
    .filter((item) => item?.name && item?.type) // 过滤当前层级的任务
    .map((item) => {
      const filteredItem = { ...item }; // 创建一个新对象
      if (Array.isArray(filteredItem.tasks) && filteredItem.tasks.length > 0) {
        // 如果 child.tasks 存在，则递归过滤
        filteredItem.tasks = filterTasks(filteredItem.tasks);
      }
      return filteredItem;
    });
}

export function removeUndefinedAndNull(task: Task) {
  if (!task) return {} as Task
  return Object.fromEntries(Object.entries(task).filter(([_, value]) => value !== undefined && value !== null)) as Task
}

/**
 * 对比 task 与 values 是否相等
 * @param task 
 * @param values 
 * @returns 
 */
export const compareTask = (task: Task, values: any): boolean => {
  // 只对比 values 中存在的字段
  return Object.keys(values).every((key) => {
    // 如果 values 中的字段在 task 中不存在，则认为不相等
    if (!(key in task)) return false;

    // 对比值是否相等
    return JSON.stringify(task[key]) === JSON.stringify(values[key]);
  });
};

export function getTasksByTaskIndex(tasks, taskIndex) {
  let taskList = tasks
  return taskIndex.reduce((prev, next) => {
    prev.push(taskList[next])
    taskList = taskList[next]?.tasks ?? []
    return prev
  }, [])
}

export function getTaskNameByIndices(data, _indices, name = 'name') {
  const indices = _indices.slice()
  // 递归终止条件：当indices为空时，返回当前data的name属性
  if (indices.length === 0) {
    return data[name]
  }

  // 取出indices的第一个元素，并从indices中移除它
  const index = indices.shift()

  // 检查当前索引是否在data的范围内
  if (index < 0 || index >= data.length) {
    return 'Index out of bounds' // 索引超出范围
  }

  // 获取当前索引对应的元素
  const currentItem = data[index]

  // 如果当前元素有tasks属性，且indices还有剩余元素，则递归调用当前函数
  if ('tasks' in currentItem && indices.length > 0) {
    return getTaskNameByIndices(currentItem.tasks, indices)
  }

  // 如果当前元素没有tasks属性，或者indices没有剩余元素，则返回当前元素的name属性
  return currentItem[name]
}

export function deepCompare(a, b) {
  const aProps = Object.keys(a)

  for (const prop of aProps) {
    const aProp = a[prop]
    const bProp = b[prop]

    if (Array.isArray(aProp) && Array.isArray(bProp)) {
      if (aProp.length !== bProp.length || !aProp.every((val, index) => deepCompare(val, bProp[index]))) {
        return false
      }
    } else if (typeof aProp === 'object' && typeof bProp === 'object') {
      if (!deepCompare(aProp, bProp)) {
        return false
      }
    } else if (aProp !== bProp) {
      return false
    }
  }

  return true
}

export function addSelectableProperty(tree) {
  tree.forEach(node => {
    if (node.children && node.children.length > 0) {
      // 如果节点有子节点，则添加 selectable: false
      node.selectable = false
      // 递归处理子节点
      addSelectableProperty(node.children)
    }
  })
}

/**
 * 处理工作流状态
 * @param runItems
 * @returns
 */
export const makeTasksStatus = (runItems, TempWorkflow, convertStatus: (status) => void) => {
  const _finalTask = JSON.parse(JSON.stringify(TempWorkflow.tasks))
  TempWorkflow.tasks.forEach((item: any, index: number) => {
    const _itemInfo = runItems.find((resItem: any) => resItem.name == item.name)
    if (_itemInfo) {
      _finalTask[index] = { ..._finalTask[index], runStatus: convertStatus(_itemInfo.status) }
    }
    // 代表有子节点的信息
    if (item.tasks && item.tasks.length > 0) {
      item.tasks.forEach((innerTask: any, innerIndex: number) => {
        const _foundItemInfo = runItems.find((resItem: any) => resItem.name === innerTask.name)
        if (_foundItemInfo) {
          _finalTask[index].tasks[innerIndex] = {
            ..._finalTask[index].tasks[innerIndex],
            runStatus: convertStatus(_foundItemInfo.status)
          }
        }

        // 继续有子节点
        if (innerTask.tasks && innerTask.tasks.length > 0) {
          innerTask.tasks.forEach((childItem: any, childIndex: number) => {
            const _foundChildItemInfo = runItems.find((resItem: any) => resItem.name === childItem.name)
            if (_foundChildItemInfo) {
              _finalTask[index].tasks[innerIndex].tasks[childIndex] = {
                ..._finalTask[index].tasks[innerIndex].tasks[childIndex],
                runStatus: convertStatus(_foundChildItemInfo.status)
              }
            }
          })
        }
      })
    }
  })
  return _finalTask
}
// 更新本地化配置以自定义相对时间的显示
moment.updateLocale('zh-cn', {
  relativeTime: {
    future: '未来 %s',
    past: '%s前',
    s: '刚刚',
    m: '1 分钟',
    mm: '%d 分钟',
    h: '1 小时',
    hh: '%d 小时',
    d: '1 天',
    dd: '%d 天',
    M: '1 个月',
    MM: '%d 个月',
    y: '1 年',
    yy: '%d 年'
  }
})

/**
 * 格式化日期为易读的字符串
 * 如果给定的日期是在过去的 3 小时内，它将返回相对于当前时间的字符串
 * 否则，它将返回格式化的日期和时间
 *
 * @param date - 要格式化的日期
 * @returns 格式化后的日期字符串
 */
export function formatDate(date) {
  // 如果日期为空，则返回空字符串
  if (!date) {
    return '--'
  }
  const mDate = moment(date)
  // 计算当前时间与给定时间的差值（以秒为单位）
  const secondsDiff = moment().diff(mDate, 'seconds')

  // 如果时间差小于 60 秒，则显示 "刚刚"
  if (secondsDiff < 180) {
    return '刚刚'
  }

  // 如果时间差在 60 秒到 3 小时之间，则使用本地化后的相对时间
  const hoursDiff = moment().diff(mDate, 'hours')
  if (hoursDiff < 3) {
    return mDate.fromNow()
  }

  // 如果时间差大于 3 小时，则使用固定格式
  return mDate.format('YYYY-MM-DD HH:mm:ss')
}

export type Choose = {
  key: string
  properties: any[]
  label: string
}

// 检查工作流是否存在变量和表达式错误引用
export const checkWorkflowChooseError = (tasks: Task[]) => {
  const _tasks = cloneDeep(tasks)
  // 1️⃣ 定义 task 检查方法
  const checkTask = async (currentTask: Task) => {
    currentTask.status = 'success'
    // 只检查当前节点，不遍历子节点
    const task = cloneDeep(currentTask)
    delete task.tasks

    checkNodeReference(_tasks, task, currentTask, 'update_task_status')
  }

  // 2️⃣ 递归检查 _tasks 中所有节点是否存在错误引用
  const checkTasks = (tasks: Task[]) => {
    tasks.forEach(checkTask)
    tasks.forEach(item => {
      if (item.tasks) {
        checkTasks(item.tasks)
      }
    })
  }

  checkTasks(_tasks)

  return _tasks
}

export const updateTaskByIndex = (tasks: any[], taskIndex: any[], values: any, type: string) => {
  // 创建单据特殊处理
  if (type === 'flow-create-task') {
    const oldDetails = get(tasks, [...taskIndex, 'details'])
    // 如果当前节点没有details，而oldDetails有值, 那么删除oldDetails
    if (!values?.details && !!oldDetails) {
      unset(tasks, [...taskIndex, 'details'])
    }
  }

  if (taskIndex.length === 2) {
    const preItem = tasks[taskIndex[0]].tasks[taskIndex[1]]
    const item = { ...preItem, ...values, type, status: 'success' }
    deleteNoUseFieldTask(item)
    tasks[taskIndex[0]].tasks.splice(taskIndex[1], 1, item)
  } else if (taskIndex.length === 1) {
    let item: any
    if (type === 'apportion') {
      item = { ...values, type, status: 'success' }
    } else {
      item = { ...tasks[taskIndex[0]], ...values, type, status: 'success' }
    }
    deleteNoUseFieldTask(item)
    tasks.splice(taskIndex[0], 1, item)
  } else if (taskIndex.length === 3) {
    const lastItem = tasks[taskIndex[0]].tasks[taskIndex[1]].tasks[taskIndex[2]]
    const item = { ...lastItem, ...values, type, status: 'success' }
    deleteNoUseFieldTask(item)
    tasks[taskIndex[0]].tasks[taskIndex[1]].tasks.splice(taskIndex[2], 1, item)
  }
}

export const updateTasks = (_tasks: Task[], submitTasks: Task[]) => {
  _tasks.forEach(task => {
    const _task = filterTaskForFormValues(task)
    const values = parseSnapshot(_task)
    const { type, levelIndex } = task;
    const taskIndex = levelIndex;

    updateTaskByIndex(submitTasks, taskIndex, values, type)

    if (!isEmpty(task.tasks) && Array.isArray(task.tasks)) {
      updateTasks(task.tasks, submitTasks)
    }
  })
}

export const markWorkflowData = (workflow: IWorkflow) => {
  const submitTasks: any[] = Object.assign([], workflow.tasks)
  const _tasks = cloneDeep(submitTasks)
  
  updateTasks(_tasks, submitTasks)

  const _workflow = { ...workflow, tasks: submitTasks }

  // 更新工作流的时候，需要判断，如果当前工作流信息没有改动，那么就不更新
  if (_workflow.hasOwnProperty('id')) {
    const _dugSuccess = trialStore.getState().dugSuccess ? 1 : 0
    _workflow.debugState = compareSessionWorkflow(_workflow) ? _dugSuccess : 0
  }

   // 手动触发保存需要将限制数据加上
   _workflow.dataLimit = 20

  return _workflow
}
