import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { IWorkflow, ITaskActionOptions } from '@/interface/workflow'
// services
import { setSessionWorkflow, compareSessionWorkflowEqual } from '@/utils/sessionWorkflow'
import { MUST_LAST_NODE } from '@/utils/constants'
import { checkWorkflowChooseError, filterTasks } from '@/utils/utils'
import MessageCenter from '@ekuaibao/messagecenter'
import { cloneDeep } from 'lodash'

interface IState {
  // 数组类型，兼容if和子节点的情况
  taskIndex: number[]
  group: string
  type: string
  workflow: IWorkflow
  bus: MessageCenter | null
  setTaskIndex: (index: number[]) => void
  setGroup: (group: string) => void
  setType: (type: string) => void
  setWorkflow: (workflow: IWorkflow, isDirty?: boolean, checkChooseError?: boolean, options?: ITaskActionOptions) => void
  setBus: (bus: MessageCenter) => void
}

const useWorkflowStore = create<IState>()(
  devtools((set: any) => ({
    taskIndex: [0],
    group: 'trigger',
    type: '',
    workflow: {
      state: 0, // 默认是停用
      label: '',
      tasks: [] as any[]
    },
    bus: null as MessageCenter | null,
    // 当前激活的节点下标
    setTaskIndex: (index: number[]) => set({ taskIndex: index }),
    // 当前节点类型分组
    setGroup: (group: string) => set({ group }),
    // 当前节点类型
    setType: (type: string) => set({ type }),
    // 当前工作流
    setWorkflow: (workflow, isDirty = true, checkChooseError = false, options: ITaskActionOptions = { actionType: 'default' }) => {
      // 对tasks进行排序，确保MUST_LAST_NODE类型的节点在最后
      if (workflow?.tasks?.length > 0) {
        workflow.tasks = workflow?.tasks?.sort((a, b) => {
          if (a.type === MUST_LAST_NODE) return 1
          if (b.type === MUST_LAST_NODE) return -1
          return 0
        })
      }
      // 排序方法放在这里，防止步骤数字错误
      let _upIndex = 0
      workflow?.tasks?.forEach?.((item: any, i) => {
        item.orderIndex = _upIndex + 1
        _upIndex = item.orderIndex
        item.levelIndex = [i]
        item?.tasks?.forEach((initem: any, j: any) => {
          initem.orderIndex = _upIndex + 1
          _upIndex = initem?.orderIndex
          initem.levelIndex = [i, j]
          initem?.tasks?.forEach?.((v: any, k) => {
            v.levelIndex = [i, j, k]
            v.orderIndex = _upIndex + 1
            _upIndex = v?.orderIndex
            item.tasksMaxLevel = 3
          })
        })
      })

      if (checkChooseError) {
        const _tasks = checkWorkflowChooseError(workflow?.tasks)
        workflow.tasks = _tasks

        // 实时异常检查不需要自动保存
        // if (!checkNodeErrors(_tasks)) {
        //   updateWorkflow(workflow.id + '', workflow)
        // }
      }
      workflow.tasks = filterTasks(workflow?.tasks)

      set({ workflow: { ...workflow, isDirty } })
      // if (['remove_task', 'copy_task', 'add_task', 'drag_task_over', 'drag_task_end', 'restore_task', 'add_workflow'].includes(options.actionType)) {
      //   validateStore.getState().setCheckWorkflowStatus('waiting')
      // }
      // 添加 bus 发送事件
      set((state: IState) => {
        setTimeout(() => {
          const newWorkflow = { ...workflow, isDirty }
          const isChangedTask = !compareSessionWorkflowEqual(workflow)
          // 恢复的时候，需要强制校验异常、检查workflow是否有实际变化
          const hasChanged = ['restore_task', 'rename_workflow', 'change_type_workflow'].includes(options.actionType) || isChangedTask
          if (hasChanged) {
            if (!['drag_task_over', 'link_detail', 'save_success_workflow', 'workflow_from_api', 'give_up_task', 'copy_workflow'].includes(options.actionType)) {
              state.bus?.emit?.('save:workflow:indexdb', { workflow: cloneDeep(newWorkflow), actionType: options.actionType })
            }
            if (['remove_task', 'copy_task', 'add_task', 'drag_task_end', 'restore_task'].includes(options.actionType)) {
              state.bus?.emit?.('force:request:workflow:stag:context:deep', { workflow: newWorkflow, actionType: options.actionType })
            }
            // if (['remove_task', 'drag_task_end'].includes(options.actionType)) {
            if (['remove_task', 'change_type_workflow', 'copy_task', 'change_trigger_type'].includes(options.actionType)) {
              state.bus?.emit?.('update:form:validate:error', { workflow: newWorkflow, actionType: options.actionType, nameMapping: options.nameMapping })
            }
          }
          setSessionWorkflow(workflow?.tasks)
        }, 0)
        
        return state;
      })
    },

    setBus: (bus: MessageCenter) => set({ bus }),
  }))
)

export default useWorkflowStore
