import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { ITaskValidationError, IWorkflow, WorkflowContext, ICheckWorkflowStatus } from '@/interface/workflow'
import { getWorkflowStageContextDeep, getWorkflowContextDeep, getWorkflowVariables } from '@/service/workflows'

interface ValidateState {
  workflowContextDeep: WorkflowContext[]
  validationErrors: ITaskValidationError[]
  formValidationErrors: ITaskValidationError[]
  popoverVisible: boolean
  workflowUpdateTime: string
  isDragging: boolean
  checkWorkflowStatus: ICheckWorkflowStatus
  indexDBSaveError: string
  setContextDeep: (pipelineId?: string) => Promise<void>
  setValidationErrors: (errors: ITaskValidationError[]) => void
  setFormValidationErrors: (errors: ITaskValidationError[]) => void
  setWorkflowUpdateTime: (workflowUpdateTime: string) => void
  setStagContextDeep: (pipelineId: string, workflow: IWorkflow) => Promise<void>
  setIsDragging: (isDragging: boolean) => void
  setCheckWorkflowStatus: (status: ICheckWorkflowStatus) => void
  setIndexDBSaveError: (error: string) => void
  resetValidateStore: () => void
}

const useValidateStore = create<ValidateState>()(
  devtools((set: any) => ({
    workflowContextDeep: [] as WorkflowContext[],
    workflowUpdateTime: '',
    validationErrors: [] as ITaskValidationError[],
    formValidationErrors: [] as ITaskValidationError[],
    popoverVisible: false as boolean,
    isDragging: false as boolean,
    checkWorkflowStatus: 'waiting' as ICheckWorkflowStatus,
    indexDBSaveError: '',
    setContextDeep: async (pipelineId?: string) => {
      if (pipelineId) {
        const res = await getWorkflowContextDeep(pipelineId)
        res && set({ workflowContextDeep: res })
      } else {
        const res = await getWorkflowVariables()
        res && set({ workflowContextDeep: res })
      }
    },
    setValidationErrors: (errors) => set({ validationErrors: errors }),
    setFormValidationErrors: (errors) => set({ formValidationErrors: errors }),
    setWorkflowUpdateTime: (workflowUpdateTime: string) => set({ workflowUpdateTime }),
    setStagContextDeep: async (pipelineId: string, workflow: IWorkflow) => {
      try {
        if (pipelineId && workflow) {
          const res = await getWorkflowStageContextDeep(pipelineId, workflow)
          res && set({ workflowContextDeep: res })
        }
      } catch (error) {
        console.log('setStagContextDeep error', error)
      }
    },
    setIsDragging: (isDragging: boolean) => set({ isDragging }),
    setCheckWorkflowStatus: (status: ICheckWorkflowStatus) => set({ checkWorkflowStatus: status }),
    setIndexDBSaveError: (error: string) => set({ indexDBSaveError: error }),
    resetValidateStore: () => set({
      workflowContextDeep: [] as WorkflowContext[],
      workflowUpdateTime: '',
      validationErrors: [] as ITaskValidationError[],
      formValidationErrors: [] as ITaskValidationError[],
      popoverVisible: false as boolean,
      isDragging: false as boolean,
      checkWorkflowStatus: 'waiting' as ICheckWorkflowStatus,
      indexDBSaveError: '',
    }),
  }))
)

export default useValidateStore
