import axios, { AxiosRequestConfig, Method, ResponseType } from 'axios'
import { message } from 'antd'

// ----------------------------------------------------------------------
// 添加 ETag 缓存存储
const etagCache = new Map<string, string>();

async function request(config: {
  method: Method
  endpoint: string
  headers?: any
  data?: any
  responseType?: ResponseType
  useEtag?: boolean
}) {
  const { method, endpoint, headers, data, responseType, useEtag } = config
  const options: AxiosRequestConfig = {
    method,
    url: endpoint,
    headers: headers,
    responseType
  }

  // 如果启用了 ETag，添加 If-None-Match 头
  const cacheKey = `${method}:${endpoint}`;

  if (useEtag && etagCache.has(cacheKey)) {
    options.headers['If-None-Match'] = etagCache.get(cacheKey);
  }
  
  if (['PATCH', 'POST', 'PUT'].includes(method)) {
    options.data = data
  } else {
    options.params = data
  }

  try {
    const response = await axios.request(options)

    // 保存新的 ETag
    if (useEtag && response.headers.etag) {
      etagCache.set(cacheKey, response.headers.etag);
    }
    
    return response.data
  } catch (error: any) {
    if (error.message === 'Network Error') {
      message.error('API-Server can not be reached. It is probably down.')
    }
    const errorResponseData = error.response?.data
    if (errorResponseData !== undefined && errorResponseData.message !== undefined) {
      message.error(errorResponseData.message)
      return errorResponseData
    }
    throw error
  }
}

export async function makeRestApiRequest(method: Method, endpoint: string, data?: object, useEtag?: boolean, showSuccessMessage: boolean = true) {
  const response = await request({
    method,
    endpoint,
    headers: {},
    data,
    useEtag,
  })

  if (response.code !== 200) {
    message.error(response.msg)
  } else if (response.code === 200 && response.msg && showSuccessMessage) {
    if (response.msg !== '修改成功' && response.msg !== '更新成功') {
      message.success(response.msg)
    }
  }

  if (response.code === 200) {
    return response.data
  }

  return Promise.reject(response)
}

export function get(endpoint: string, params?: object, headers?: object, responseType?: ResponseType) {
  return request({ method: 'GET', endpoint, headers, data: params, responseType })
}

export function post(endpoint: string, params?: object, headers?: object) {
  return request({ method: 'POST', endpoint, headers, data: params })
}

export function put(endpoint: string, params?: object, headers?: object) {
  return request({ method: 'PUT', endpoint, headers, data: params })
}

export function patch(endpoint: string, params?: object, headers?: object) {
  return request({ method: 'PATCH', endpoint, headers, data: params })
}
